import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../reusable/Nav";
import FilterCard from "../reusable/FilterCard";
import axiosInterceptor from "../../../helpers/axiosInterceptor";
import CradCOL from "../reusable/CradCOL";
import FilterAll from "../reusable/FilterAll";
import "./dashboard.css";
import moment from "moment";
import {
  selectfromDateDashBoard,
  selecttodateDashboard,
  setfromDateDashBoard,
  settodateDashboard,
} from "../../../features/DateRange/DateRangeSlice";
import { useDispatch, useSelector } from "react-redux";

function timeAgo(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  if (seconds < 60) {
    return `${Math.floor(seconds)} seconds ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes === 1) {
    return `1 minute ago`;
  }
  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours === 1) {
    return `1 hour ago`;
  }
  if (hours < 24) {
    return `${hours} hours ago`;
  }

  const days = Math.floor(hours / 24);
  if (days === 1) {
    return `1 day ago`;
  }
  if (days < 30) {
    return `${days} days ago`;
  }

  const months = Math.floor(days / 30);
  if (months === 1) {
    return `1 month ago`;
  }
  if (months < 12) {
    return `${months} months ago`;
  }

  const years = Math.floor(months / 12);
  if (years === 1) {
    return `1 year ago`;
  }

  return `${years} years ago`;
}
const Dashboard = ({ value }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fromDate = useSelector(selectfromDateDashBoard);
  const toDate = useSelector(selecttodateDashboard);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [mydata, setMYdata] = useState("");
  const [OnlyCount, setOnlyCount] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [multiSelect, setmultiSelect] = useState([]);
  const [FromData, setFromData] = useState();
  const [endDate, setendDate] = useState();

  const handleDateRangeChange = (selectedRange) => {
    setDateRange(selectedRange);
    dispatch(
      setfromDateDashBoard(moment(selectedRange[0]).format("MM/DD/YYYY"))
    );
    dispatch(settodateDashboard(moment(selectedRange[1]).format("MM/DD/YYYY")));
  };

  const handleClearDateRange = () => {
    setDateRange([]);
  };

  useEffect(() => {
    if (lastRefresh) {
      const interval = setInterval(() => {
        setTimeElapsed(timeAgo(lastRefresh));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [lastRefresh]);

  const handleRefresh = ({}) => {
    setLastRefresh(new Date());
    setTimeElapsed(null); // Reset the time elapsed when the button is clicked
    window.location.reload(); // Refresh the page
  };

  // refresh button time functionality end --------
  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      window.location.href = "/";
    }
  }, []);

  const getdashboardData = async (filterstatus, data1, data2) => {
    console.log("clicked");
    const res = await axiosInterceptor.post(`/all-collection-count`, {
      start_date: fromDate,
      end_date: toDate,
    });
    const resData = await res?.data?.result;
    console.log(resData.usersClickOnCount[0], "data");
    setMYdata(resData);
    setOnlyCount(resData.usersClickOnCount[0]);
  };
  const getdashboardData1 = async (per) => {
    console.log("clicked");
    const res = await axiosInterceptor.post(`/all-collection-count`, {
      start_date: "",
      end_date: "",
    });
    const resData = await res?.data?.result;
    console.log(resData.usersClickOnCount[0], "data");
    setMYdata(resData);
    setOnlyCount(resData.usersClickOnCount[0]);
  };
  useEffect(() => {
    getdashboardData(1);
  }, []);
  return (
    <div>
      <Nav />
      {/* nav below section start  */}
      <section className="bg-[#F9EDDD]  px-20 py-2">
        <h3 className="text-[#717171] text-base font-semibold">
          Leads from StrongBuild
        </h3>
        <p className="text-[#E98700] text-sm font-semibold">
          ({mydata["totalCustomerCount"]} Leads )
        </p>
      </section>
      {/* nav below section end  */}

      {/* welcome admin start from here ----  */}
      <div className="  px-20  my-10">
        <p className="text-[#717171] font-bold text-xl">WELCOME ADMIN</p>
      </div>

      {/* welcome adming end here ------  */}
      {/* filter with date section start  */}

      <div className=" px-20 mt-2">
        <p className="Filter_Title mb-2">Filter</p>
        <div>
          <FilterAll
            handleDateRangeChange={handleDateRangeChange}
            handleClearDateRange={handleClearDateRange}
            dateRange={dateRange}
            ApiCall={getdashboardData}
            ApiCall1={getdashboardData1}
            hideEVent={false}
            setmultiSelect={setmultiSelect}
            setFromData={setFromData}
            setendDate={setendDate}
          />
        </div>
      </div>
      {/* filter with date section end  */}
      {/* All cards form quiz sign up etc... start  */}
      <section className=" flex justify-between  px-20 gap-3 mt-5 ">
        <div className="border-1 basis-1/4 rounded-md border-[#E98700] p-1 bg-[#F6F6F6] cursor-pointer">
          <p className="text-base font-semibold mb-2 font-['Open_Sans'] text-[#000000]">
            Forms
          </p>
          <CradCOL
            title={"Start Planning Now"}
            value={mydata["Start Planning Now"]}
            dataPlann={dashboardData?.plannings}
          />
          <CradCOL
            title={"Already Have a Design"}
            value={mydata["Already Have a Design"]}
          />
        </div>
        <div className="border-1 basis-1/4 rounded-md border-[#E98700] p-1 bg-[#F6F6F6] cursor-pointer">
          <p className="text-base font-semibold mb-2 font-['Open_Sans'] text-[#000000]">
            Quiz
          </p>
          <CradCOL title={"Take our Quiz"} value={mydata["Take our Quiz"]} />
        </div>
        <div className="border-1 basis-1/4 rounded-md border-[#E98700] p-1 bg-[#F6F6F6] cursor-pointer">
          <p className="text-base font-semibold mb-2 font-['Open_Sans'] text-[#000000]">
            CTAs
          </p>
          <CradCOL
            title={"Trending Designs"}
            // subtitle={"Trending Designs"}
            value={OnlyCount?.Trending_Designs}
          />
          <CradCOL
            title={"Expert Architects"}
            // subtitle={"Expert Architects"}
            value={OnlyCount?.Expert_Architect}
          />
          <CradCOL
            title={"Strong Build Homes"}
            // subtitle={"Strong Build Homes"}
            value={OnlyCount?.Strong_Build_Homes}

            // data is not coming
          />
          <CradCOL
            title={"Traditional Homes"}
            // subtitle={"Traditional Homes"}
            // value={OnlyCount?.Traditional_Homes}
            value={OnlyCount?.Traditional_Homes}
          />
          <CradCOL
            title={"Strong Build Method of Construction"}
            // subtitle={"Strong Build Method of Construction"}
            value={OnlyCount?.Strong_Build_Method_of_Construction}
          />
          <CradCOL
            title={"About us"}
            // subtitle={"About us"}
            value={OnlyCount?.About_us}
          />
        </div>
        {/* <div className="border-1 basis-1/5 rounded-md border-[#E98700] p-1 bg-[#F6F6F6] cursor-pointer">
          <p className="text-base font-semibold mb-2 font-['Open_Sans'] text-[#000000]">
            CTAs
          </p>
          <CradCOL
            title={"view all _1"}
            subtitle={"Trending Designs"}
            value={"20,003"}
          />
          <CradCOL
            title={"view all _2"}
            subtitle={"Expert Architects"}
            value={"20,003"}
          />
          <CradCOL
            title={"view all _3"}
            subtitle={"Strong Build Homes"}
            value={"20,003"}
          />
          <CradCOL
            title={"view all _4"}
            subtitle={"Traditional Homes"}
            value={"20,003"}
          />
        </div> */}
        <div className="border-1 basis-1/4 rounded-md border-[#E98700] p-1 bg-[#F6F6F6] cursor-pointer">
          <p className="text-base font-semibold mb-2 font-['Open_Sans'] text-[#000000]">
            Social Links
          </p>
          <CradCOL title={"Facebook"} value={OnlyCount?.Facebook} />
          <CradCOL title={"Twitter"} value={OnlyCount?.Twitter} />
          <CradCOL title={"Youtube"} value={OnlyCount?.Youtube} />
          <CradCOL title={"Instagram"} value={OnlyCount?.Instagram} />
        </div>
      </section>
      {/* All cards form quiz sign up etc... end  */}
    </div>
  );
};

export default Dashboard;
