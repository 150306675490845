import React, { useEffect, useState } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setTodateAllUser,
  setfromDateDashBoard,
  setfromdateALLUser,
  settodateDashboard,
} from "../../../features/DateRange/DateRangeSlice";
import { useDispatch } from "react-redux";
import { LastActivityDate } from "../../../schemas/validation";

function timeAgo(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  if (seconds < 60) {
    return `${Math.floor(seconds)} seconds ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes === 1) {
    return `1 minute ago`;
  }
  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours === 1) {
    return `1 hour ago`;
  }
  if (hours < 24) {
    return `${hours} hours ago`;
  }

  const days = Math.floor(hours / 24);
  if (days === 1) {
    return `1 day ago`;
  }
  if (days < 30) {
    return `${days} days ago`;
  }

  const months = Math.floor(days / 30);
  if (months === 1) {
    return `1 month ago`;
  }
  if (months < 12) {
    return `${months} months ago`;
  }

  const years = Math.floor(months / 12);
  if (years === 1) {
    return `1 year ago`;
  }

  return `${years} years ago`;
}

const FilterAll = ({
  handleDateRangeChange,
  handleClearDateRange,
  ApiCall,
  ApiCall1,
  hideEVent,
  dateRange,
  exportData,
  loader,
  handleChange,
  setmultiSelect,
  multiSelect,
  setendDate,
  setFromData,
  perPage,
}) => {
  const [timeElapsed, setTimeElapsed] = useState(null);
  const [lastRefresh, setLastRefresh] = useState(null);
  const dispatch = useDispatch();

  const animatedComponents = makeAnimated();
  const options = [
    { value: "Start Planning Now", label: "Start Planning Now" },
    { value: "Already Have a Design", label: "Already Have a Design" },
    { value: "Take our Quiz", label: "Take our Quiz" },
    { value: "Trending Vew All", label: "Trending View All" },
    { value: "About_us", label: "About us" },
    { value: "Expert Architect Vew All", label: "Expert Architect View All" },
    {
      value: "StrongBuild Homes Vew Al",
      label: "StrongBuild Homes View All",
    },
    {
      value: "Traditional Homes Vew All",
      label: "Traditional Homes View All",
    },
    {
      value: "Strong_Build_Method_of_Construction",
      label: "Strong Build Method of Construction",
    },
    {
      value: "faq",
      label: "FAQ",
    },
  ];

  useEffect(() => {
    if (lastRefresh) {
      const interval = setInterval(() => {
        setTimeElapsed(timeAgo(lastRefresh));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [lastRefresh]);
  const handleRefresh = () => {
    setLastRefresh(new Date());
    setTimeElapsed(null); // Reset the time elapsed when the button is clicked
    window.location.reload(); // Refresh the page
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const Option = (props) => {
    return (
      <div className="d-flex justify-content-center w-72 align-items-center cursor-pointer">
        <components.Option {...props}>
          {/* <input
            style={{ background: "white" }}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "} */}
          <label style={{ color: "black", cursor: "pointer" }}>
            {props.label}
          </label>
        </components.Option>
      </div>
    );
  };

  const disabledFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0);

    return selectedDate > today;
  };
  return (
    <div className=" flex justify-between items-center">
      <div className=" w-[55%] flex items-center border-1 p-3 rounded-md border-[#E98700]">
        <div className=" flex justify-between items-center w-full">
          <div>
            <p className="mb-2 title_Filter">By Date</p>
            <DateRangePicker
              className="custom-date-picker"
              onChange={handleDateRangeChange}
              value={dateRange}
              cleanable={false}
              character="-"
              id="filter_range"
              placeholder="DD/MM/YYYY - DD/MM/YYYY"
              ranges={LastActivityDate}
              // ranges={ranges}
              format="dd/MM/yyyy"
              // character="-"
              // style={{ zIndex: "999", border: "none" }}
              // onChange={onChange1}
              // ranges={Ranges}
              // defaultValue={dateRangeactivity}
              disabledDate={disabledFutureDate}
              showToday
            />
          </div>
          {hideEVent && (
            <div>
              <p className="mb-2 title_Filter">Select Event</p>
              <Select
                closeMenuOnSelect={false}
                components={{
                  Option,
                  MultiValue,
                  animatedComponents,
                }}
                value={multiSelect}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary: "#FFFFFF",
                  },
                })}
                hideSelectedOptions={false}
                // value={multiSelect}
                placeholder="Select  Event"
                options={options}
                onChange={handleChange}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: state.isFocused ? "1px solid #fcc200" : "",
                    boxShadow: state.isFocused ? "none" : "",
                    cursor: "pointer",

                    "&:hover": {
                      border: "1px solid #fcc200",
                      boxShadow: "none",
                    },
                  }),
                }}
              />
            </div>
          )}
          <div className=" flex items-center justify-start mt-4">
            <button
              className="Submit_btn px-3 py-2 me-2"
              onClick={() => {
                setendDate("");
                setFromData("");

                ApiCall(false, 1, perPage);
              }}
            >
              Submit
            </button>
            <button
              className="Reset_btn px-3 py-2"
              onClick={() => {
                handleClearDateRange();
                setmultiSelect([]);
                dispatch(setfromdateALLUser(""));
                dispatch(setTodateAllUser(""));
                dispatch(setfromDateDashBoard(""));
                dispatch(settodateDashboard(""));
                ApiCall1(perPage);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <div>
        {hideEVent && (
          <Button
            onClick={() => exportData()}
            variant="outlined"
            color="warning"
            startIcon={<GetAppIcon />}
          >
            {loader ? (
              <CircularProgress size={20} color="warning" />
            ) : (
              <span style={{ textTransform: "capitalize" }}>Export</span>
            )}
          </Button>
        )}
        <div className=" basis-[20%] flex flex-col items-end justify-center ">
          <button
            className="bg-[#070707] mt-5 rounded-sm flex items-center justify-between gap-2 px-4 py-2 hover:bg-[#333]"
            onClick={handleRefresh}
          >
            <span>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2356 1.76493C9.6063 1.13078 8.84244 0.645903 8.0004 0.346107C7.15835 0.0463113 6.25963 -0.0607466 5.37061 0.0328393C2.6153 0.310274 0.347981 2.54474 0.040167 5.29659C-0.372755 8.93323 2.44262 12 5.99375 12C7.12748 12 8.23795 11.6787 9.19614 11.0735C10.1543 10.4682 10.9209 9.60386 11.4068 8.58081C11.647 8.07843 11.2866 7.50107 10.7311 7.50107C10.4533 7.50107 10.1905 7.65103 10.0704 7.89847C9.63452 8.83502 8.88897 9.59306 7.95919 10.0451C7.02941 10.4971 5.97213 10.6155 4.9652 10.3804C3.29849 10.013 1.95462 8.65579 1.60176 6.99119C1.45343 6.33304 1.45515 5.65001 1.60679 4.99261C1.75842 4.33522 2.0561 3.72028 2.47779 3.19328C2.89949 2.66628 3.43442 2.24071 4.04301 1.94805C4.6516 1.65538 5.31828 1.50311 5.99375 1.50249C7.24002 1.50249 8.35115 2.01987 9.16198 2.83717L8.02832 3.96941C7.55534 4.44179 7.88568 5.2516 8.55386 5.2516H11.2491C11.662 5.2516 11.9999 4.91418 11.9999 4.50178V1.80992C11.9999 1.14258 11.1891 0.805156 10.7161 1.27754L10.2356 1.76493Z"
                  fill="#E8E8E8"
                />
              </svg>
            </span>
            <span className="text-white font-bold text-xs font-['Satoshi']">
              Refresh
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterAll;
