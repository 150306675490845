import React, { useEffect, useState } from "react";
import "./userInfo.css";
import Nav from "../reusable/Nav";
import FilterCard from "../reusable/FilterCard";
import Table from "../reusable/Table";
import { Pagination } from "antd";
import { PaginationProps } from "antd";
import axiosInterceptor from "../../../helpers/axiosInterceptor";
import "../reusable/reusable.css";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../reusable/CustomPagination";
import { useParams } from "react-router-dom";
import FilterAll from "../reusable/FilterAll";
import { saveAs } from "file-saver";
import moment from "moment";
import Cookies from "js-cookie";
import { Button } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoDataFound from "../../../asset/svg/NoDataFound.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTodateAllUser,
  selectfromdateALLUser,
  setTodateAllUser,
  setfromdateALLUser,
} from "../../../features/DateRange/DateRangeSlice";

const UserInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fromDate = useSelector(selectfromdateALLUser);
  const toDate = useSelector(selectTodateAllUser);
  const [leadData, setLeadData] = useState([]);
  const [leadId, setLeadId] = useState("");
  const [view, setView] = useState("allLeads");
  const [selectedIds, setSelectedIds] = useState([]);
  console.log(selectedIds, "selectedIds");
  const [loader, setloader] = useState(false);
  const [multiSelect, setmultiSelect] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [FromData, setFromData] = useState();
  const [endDate, setendDate] = useState();
  const [check, setCheck] = useState(false);

  // card section start from here ---
  const [urlSearch, setUrlSearch] = useState("");
  // card section end here -----

  // pagintaion start ---------
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState();
  const [toDateData, setToDateData] = useState("");

  const [Period, setPeriod] = useState("");
  const [custData, setCustdata] = useState("");
  const [dataPlanning, setDataPlanning] = useState("");

  // console.log(totalCount, totalNum, "hhhhh");
  // const [searchterm, setSearchTerm] = useState("");

  // pagination end ------
  // const [leadData, setLeadData] = useState([]);

  //

  const handleDateRangeChange = (selectedRange) => {
    if (selectedRange) {
      setDateRange(selectedRange);
      dispatch(
        setfromdateALLUser(moment(selectedRange[0]).format("MM/DD/YYYY"))
      );
      dispatch(setTodateAllUser(moment(selectedRange[1]).format("MM/DD/YYYY")));
    }
  };

  const customerData = async (filterApply, value, pagesize) => {
    let payload = {};

    Object.assign(payload, {
      start_date: !filterApply && fromDate,
      end_date: !filterApply && toDate,
    });

    console.log(
      moment(dateRange[0]?.toLocaleDateString()).format("MM/DD/YYYY"),
      "dateRange[0]?.toLocaleDateString()"
    );

    payload.type = !filterApply && multiSelect.value;
    //   filterstatus && multiSelect?.map((val) => val.label).join("");
    const res = await axiosInterceptor.post(
      `/all-collection-by-model?pageno=${value}&limit=${pagesize}`,
      payload
    );
    const resData = await res?.data?.result;
    setLeadData(resData?.planningData);
    setCurrentPage(resData?.currentPage);

    setTotalCount(resData?.customerData);

    // setLimit(resData?.customerData?.limit);
  };
  const customerData1 = async (per) => {
    //   filterstatus && multiSelect?.map((val) => val.label).join("");
    const res = await axiosInterceptor.post(
      `/all-collection-by-model?pageno=1&limit=${per}`,
      {
        start_date: "",
        end_date: "",
        type: "",
      }
    );
    const resData = await res?.data?.result;
    setLeadData(resData?.planningData);
    setCurrentPage(resData?.currentPage);
    setTotalCount(resData?.customerData);

    // setLimit(resData?.customerData?.limit);
  };
  const handleChangePage = (value, pagesize) => {
    // setCurrentPage(pagesize);
    setCurrentPage(value);
    setPerPage(pagesize);
    customerData(false, value, pagesize);
  };

  // useEffect(() => {
  //   customerData(false);
  // }, [currentPage, perPage]);
  useEffect(() => {
    customerData(false, 1, perPage);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      window.location.href = "/";
    }
  }, []);
  const fileNameSplit = (str) => {
    return str.split("/").at(-1).split("-").slice(1).join("-");
  };
  const exportData = async () => {
    console.log("leadData", selectedIds);
    setloader(true);
    const res = await axiosInterceptor.post(`/exports-customer-data`, {
      customers_id: selectedIds,
      format: "csv",
    });
    saveAs(
      `${process.env.REACT_APP_IMG_URL}/${res.data.result.file}`,
      `${fileNameSplit(res.data.result.file)}`
    );
    setloader(false);
    const res1 = await axiosInterceptor.post(`/delete-exports-customer-file`, {
      fileName: res.data.result.file,
    });
  };
  const handleChange = (data) => {
    setmultiSelect(data);
  };
  const handleClearDateRange = () => {
    setDateRange([]);
  };

  return (
    <section>
      <Nav />
      {/* nav below section 2---- */}
      <section className="bg-[#F9EDDD]  px-20 py-2">
        <h3 className="text-[#717171] text-base font-semibold">
          Leads from StrongBuild
        </h3>
        <p className="text-[#E98700] text-sm font-semibold">
          ({totalCount} Leads)
        </p>
      </section>
      {/* nav below section222---  */}
      <div className="px-20 flex justify-between items-center ">
        <div className="font-['Open_Sans'] w-full">
          <h2
            className="text-base font-bold text-[#979698] mb-2 mt-4 font-['Open_Sans']"
            onClick={() => console.log(selectedIds)}
          >
            Filter
          </h2>
          <FilterAll
            hideEVent={true}
            exportData={exportData}
            loader={loader}
            handleDateRangeChange={handleDateRangeChange}
            handleClearDateRange={handleClearDateRange}
            dateRange={dateRange}
            handleChange={handleChange}
            ApiCall={customerData}
            ApiCall1={customerData1}
            setmultiSelect={setmultiSelect}
            multiSelect={multiSelect}
            setFromData={setFromData}
            setendDate={setendDate}
            perPage={perPage}
          />
        </div>
      </div>

      {/* table component start here  */}
      <div className="px-20 mt-10">
        {/* <Table /> */}

        {leadData?.length > 0 ? (
          <div className="mytable">
            <table
              className="table "
              style={{
                height: "10px",
                overflowY: "scroll",
              }}
            >
              <thead className="bg-[#333333] text-white thead">
                <tr>
                  <th className="theading">
                    <input
                      type="checkbox"
                      // checked={false}
                      // onChange={(e) => {
                      //   const isChecked = e.target.checked;
                      //   const updatedIds = isChecked
                      //     ? leadData.map((de) => de._id)
                      //     : [];
                      //   setSelectedIds(updatedIds);
                      //   setLeadData(
                      //     leadData.map((de) => {
                      //       de.select = isChecked;
                      //       return de;
                      //     })
                      //   );
                      // }}

                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const updatedIds = isChecked
                          ? leadData
                              .slice(0, totalCount) // Select only the first 10 checkboxes
                              .map((de) => de._id)
                          : [];
                        setSelectedIds(updatedIds);

                        setLeadData(
                          leadData.map((de, index) => {
                            if (index < 10) {
                              // Update only the first 10 checkboxes
                              de.select = isChecked;
                            }
                            return de;
                          })
                        );
                      }}
                      style={{
                        borderRadius: "6px",
                        height: "22px",
                        width: "22px",
                        paddingTop: "2px",
                        fontSize: "15.5px",
                        marginLeft: "2px",
                      }}
                      className="mr-2 largerCheckbox"
                      // Rest of the attributes...
                    />
                  </th>
                  <th>Sl. No.</th>
                  <th>
                    <span className="text-sm">Name</span>
                    <span className="ml-5 text-[#818181] text-xs">
                      Total- {leadData?.length}
                    </span>
                  </th>
                  <th>Mobile Number </th>

                  <th>
                    <span className="text-sm">Email ID</span>
                    <span className="ml-5 text-[#818181] text-xs">
                      Total- {leadData?.length}
                    </span>
                  </th>

                  <th className="theading_last text-[14px]">Action</th>
                </tr>
              </thead>
              <tbody>
                {leadData.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={(e) => {
                        let getCurrentCellIndex = e.target.cellIndex;
                        let getLastCellIndex = document.querySelector(
                          "table tr:last-child td:nth-child(1)"
                        ).cellIndex;

                        if (
                          getCurrentCellIndex !== getLastCellIndex &&
                          getCurrentCellIndex !== undefined
                        ) {
                          navigate("/leadDetail");
                          // setGetId(item?.customer_id);

                          Cookies.set("CustomerID", item?._id, {
                            expires: 1,
                            path: "/",
                          });
                          // setView("leadInfo");
                        }
                      }}
                      className="cursor-pointer"
                    >
                      <td>
                        <input
                          type="checkbox"
                          className="largerCheckbox2"
                          checked={
                            item.select || selectedIds[index] === item._id
                          }
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const itemId = item._id;
                            setSelectedIds((prevIds) => {
                              if (isChecked) {
                                return [...prevIds, itemId];
                              } else {
                                return prevIds.filter((id) => id !== itemId);
                              }
                            });
                            setLeadData(
                              leadData.map((sd) => {
                                if (sd._id === itemId) {
                                  sd.select = isChecked;
                                }
                                return sd;
                              })
                            );
                          }}
                        />
                      </td>
                      {/* <td> {(myState.currentPage - 1) * 10 + index + 1}</td> */}
                      {/* <td>{index + 1}</td> */}

                      <td>{(currentPage - 1) * perPage + index + 1}</td>
                      <td>
                        <div className="flex  items-center">
                          <span>
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14 0.666687C6.63999 0.666687 0.666656 6.64002 0.666656 14C0.666656 21.36 6.63999 27.3334 14 27.3334C21.36 27.3334 27.3333 21.36 27.3333 14C27.3333 6.64002 21.36 0.666687 14 0.666687ZM14 6.00002C16.5733 6.00002 18.6667 8.09335 18.6667 10.6667C18.6667 13.24 16.5733 15.3334 14 15.3334C11.4267 15.3334 9.33332 13.24 9.33332 10.6667C9.33332 8.09335 11.4267 6.00002 14 6.00002ZM14 24.6667C11.2933 24.6667 8.09332 23.5734 5.81332 20.8267C8.1488 18.9943 11.0315 17.9984 14 17.9984C16.9685 17.9984 19.8512 18.9943 22.1867 20.8267C19.9067 23.5734 16.7067 24.6667 14 24.6667Z"
                                fill="#494847"
                              />
                            </svg>
                          </span>
                          <span className="ml-3 text-sm font-semibold text-[#717171]">
                            {item.name || "-"}
                          </span>
                        </div>
                      </td>
                      <td className="font-semibold text-[#717171] text-sm">
                        <span> +91 </span> {item.phone || "-"}
                      </td>
                      <td className="text-[#717171] text-sm font-semibold">
                        {item.email || "-"}
                      </td>
                      {/* <td>{item.status || "-"}</td> */}
                      <td>
                        <Button
                          onClick={() => {
                            navigate("/leadDetail");

                            Cookies.set("CustomerID", item?._id, {
                              expires: 1,
                              path: "/",
                            });
                          }}
                          variant="outlined"
                          disabled
                          style={{ background: "#F8F8F8" }}
                        >
                          <RemoveRedEyeIcon color="warning" />
                        </Button>
                        {/* <button className="border-1 border-[#E98700] p-2 rounded-xl text-xs font-semibold text-[#717171]">
                        view profile &gt;
                      </button> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-center  items-center w-full my-10 ">
            <img src={NoDataFound} alt="..." />
          </div>
        )}
      </div>
      {/* table component end here -------  */}

      {/* pagination starts from here ------------  */}
      <div className="px-20 mt-10 mb-10">
        <CustomPagination
          handleChangePage={handleChangePage}
          PerPage={perPage}
          TotalCount={totalCount}
          Page={currentPage}
        />
      </div>

      {/* pagination end here ------------  */}
    </section>
  );
};

export default UserInfo;
