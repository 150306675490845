import React, { useState } from "react";
import { Pagination } from "antd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const CustomPagination = ({ PerPage, TotalCount, handleChangePage, Page }) => {
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a className="border text-[#717171] p-2 ">
          {" "}
          <KeyboardArrowLeftIcon />
          Previous
        </a>
      );
    }
    if (type === "next") {
      return (
        <a className="border text-[#717171] p-2">
          Next <KeyboardArrowRightIcon />
        </a>
      );
    }
    return originalElement;
  };
  return (
    <div>
      <div
      // style={{
      //   position: "absolute",
      //   top: "133%",
      //   paddingBottom: "30px",
      // }}
      >
        <Pagination
          onChange={handleChangePage}
          PerPage={PerPage}
          total={TotalCount}
          showTotal={(total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} Results`
          }
          current={parseInt(Page)}
          pageSizeOptions={[10, 20, 50, 100]}
          showSizeChanger
          itemRender={itemRender}
        />
      </div>
    </div>
  );
};

export default CustomPagination;
