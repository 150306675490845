import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInterceptor from "../../../helpers/axiosInterceptor";
import { useFormik } from "formik";
import { logInSchema } from "../../../schemas/validation";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../reuse/Loader";
// import login_logo from "../../../asset/svg/logo.svg";
import "../../../components/styles.css";
import loginLogo from "../../../asset/svg/loginLogo.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const Login = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const [btnLoader, setBtnLoader] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: logInSchema,
      onSubmit: (values) => {
        setBtnLoader(true);
        axiosInterceptor
          .post(`/login`, {
            email: values.email,
            password: values.password,
          })
          .then((res) => {
            localStorage.setItem("token", res.data.result.token);
            localStorage.setItem("ID", res.data.result._id);
            let status = res.status;
            if (status === 200) {
              navigate("/dashboard");
              setBtnLoader(false);
            }
          })
          .catch((err) => {
            setBtnLoader(false);
            toast.error("Check Login Credential");
          });
      },
    });
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className="">
      <div className="login_containers flex justify-center items-center">
        <form onSubmit={handleSubmit}>
          <div className=" login_box">
            <div className=" flex justify-center items-center">
              <img src={loginLogo} alt="logo" className="w-29 pt-3 pb-2" />
            </div>
            <div className="mt-2 login_box_admin">
              <div>
                <p className="text-center text-[#575757] font-bold text-2xl admin_login_text">
                  Admin Login
                </p>
                <div className="flex flex-col  input_box">
                  <label
                    htmlFor="email"
                    className="login_label text-[#7C7C7C] text-base mb-2"
                  >
                    User ID
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter User Id"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="border-2"
                  />
                  {errors.email && touched.email && (
                    <p className="mt-2 error_mess ">{errors.email}</p>
                  )}
                </div>
                <div className="flex flex-col  input_box2 mt-4">
                  <label
                    htmlFor="password"
                    className="login_label text-[#7C7C7C] text-base mb-2"
                  >
                    Password
                  </label>
                  <div
                    className="flex justify-between items-center border-2"
                    style={{ borderRadius: "2px", padding: "4px 0px" }}
                  >
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Enter  Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                    />
                    <div>
                      {passwordShown ? (
                        <VisibilityIcon
                          className=" mr-2  cursor-pointer"
                          onClick={togglePassword}
                        />
                      ) : (
                        <VisibilityOffIcon
                          className=" mr-2  cursor-pointer"
                          onClick={togglePassword}
                        />
                      )}
                      {/*  */}
                    </div>
                  </div>
                  {errors.password && touched.password && (
                    <p className="mt-2 error_mess">{errors.password}</p>
                  )}
                </div>
                <div className="pb-14">
                  <button type="submit" className="login_btn">
                    {btnLoader && <Loader />}
                    <p className="mb-0 mx-4">Login</p>
                  </button>
                  <p
                    className="w-full text-center mt-4
                  "
                  >
                    Version: {process.env.REACT_APP_VERSION}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* <ToastContainer /> */}
      </div>
      {/* <div className="login_left"></div>
      <div className="login_right">
        <img src={login_logo} className="login_logo" alt="login_logo" />
        <form className="login_form" onSubmit={handleSubmit}>
          <h2> Login to StrongBuild </h2>
          <div className="input_form">
            <div className="input_details">
              <label htmlFor="email" className="login_label">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="border-2"
              />
              {errors.email && touched.email && (
                <p className="mt-2 error_mess ">{errors.email}</p>
              )}
            </div>
            <div className="input_details">
              <label htmlFor="password" className="login_label">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className="border-2"
              />
              {errors.password && touched.password && (
                <p className="mt-2 error_mess">{errors.password}</p>
              )}
            </div>
            <button type="submit" className="login_btn">
              {btnLoader && <Loader />}
              <p className="mb-0 mx-4">Login</p>
            </button>
          </div>
        </form>
      </div>
      <ToastContainer /> */}
    </div>
  );
};
export default Login;
