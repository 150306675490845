import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partnerJoiningDate: "",
  fromdateALLUser: "",
  TodateAllUser: "",
  fromDateDashBoard: "",
  todateDashboard: "",
};

export const DateRangeSlice = createSlice({
  name: "DateRangeSlice",
  initialState,
  reducers: {
    setPartnerJoiningDate: (state, action) => {
      state.partnerJoiningDate = action.payload;
    },
    setfromdateALLUser: (state, action) => {
      state.fromdateALLUser = action.payload;
    },
    setTodateAllUser: (state, action) => {
      state.TodateAllUser = action.payload;
    },
    setfromDateDashBoard: (state, action) => {
      state.fromDateDashBoard = action.payload;
    },
    settodateDashboard: (state, action) => {
      state.todateDashboard = action.payload;
    },
  },
});

// this is for dispatch
export const {
  settodateDashboard,
  setfromDateDashBoard,
  setPartnerJoiningDate,
  setfromdateALLUser,
  setTodateAllUser,
} = DateRangeSlice.actions;

export const selectfromdateALLUser = (state) =>
  state.DateRangeSlice.fromdateALLUser;

export const selectTodateAllUser = (state) =>
  state.DateRangeSlice.TodateAllUser;
export const selectfromDateDashBoard = (state) =>
  state.DateRangeSlice.fromDateDashBoard;
export const selecttodateDashboard = (state) =>
  state.DateRangeSlice.todateDashboard;

// this is for configureStore
export default DateRangeSlice.reducer;
