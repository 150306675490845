import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/admin/login/Login";
// import { Dashboard } from "@mui/icons-material";
import UserInfo from "./components/admin/userInfo/UserInfo";
import Dashboard from "./components/admin/dashboard/Dashboard";
import UserPersonal from "./components/admin/userPersonalInfo/UserPersonal";
import "./index.css";
import LeadDetail from "./components/admin/detailsPage/LeadDetail";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// import LeadDetails from "./components/admin/LeadDetails";
// import AllLeads from "./components/admin/AllLeads";

const App = () => {
  // const history = useNavigate();

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          {/* <Route exact path="/admin" element={<AllLeads />} /> */}
          {/* <Route exact path="/leadDetails" element={<LeadDetails />} /> */}
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/userInfo" element={<UserInfo />} />
          <Route exact path="/leadDetail" element={<LeadDetail />} />
          <Route exact path="/allleads" element={<UserInfo />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
};

export default App;
