import React from "react";
import { useNavigate } from "react-router-dom";

const CradCOL = ({ title, value, subtitle }) => {
  const navigate = useNavigate();
  return (
    <div
      // onClick={() => navigate(`/userInfo?pagetype=${title}`)}
      className="my-2"
    >
      <div className=" rounded-md h-32 mx-2 py-3 text-center bg-[#444444] flex flex-col gap-2">
        <div className="flex justify-end items-center pr-2">
          {/* <svg
            width="3"
            height="10"
            viewBox="0 0 3 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1.15822" cy="8.2693" r="1.12795" fill="#D9D9D9" />
            <circle cx="1.15822" cy="4.88545" r="1.12795" fill="#D9D9D9" />
            <circle cx="1.15822" cy="1.50166" r="1.12795" fill="#D9D9D9" />
          </svg> */}
        </div>
        <h2 className="text-xs font-bold text-white font-['Open_Sans']">
          {title}
        </h2>
        {subtitle && (
          <p className="text-[#B8B8B8] text-[8px] font-[400]">{subtitle}</p>
        )}
        <p className="text-[#F68F00] font-bold text-2xl font-['Open_Sans']">
          {value || "0"}
        </p>
        {/* <div className="flex justify-center">
          <button className="border-1 border-[#F68F00] text-[8px] px-3 py-[2px] text-[#F68F00CC] font-['Open_Sans'] bg-[#F69C1F21] rounded-sm w-20 ">
            View Stats
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default CradCOL;
