import * as Yup from "yup";
import {
  startOfDay,
  endOfDay,
  subMonths,
  subYears,
  addMonths,
  addYears,
} from "date-fns";

export const logInSchema = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Please Enter Email"),
  password: Yup.string()

    // remember to change password limit to 6 later
    .min(3, "Password must be at least 3 characters")
    .required("Please Enter password"),
});

export function validateFileType(value) {
  let error;
  if (!value) {
    error = "File Type is Required";
  }
  return error;
}

// date
export const LastActivityDate = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
    closeOverlay: false,
  },
  {
    label: "Month",
    value: [endOfDay(subMonths(new Date(), 1)), startOfDay(new Date())],
    closeOverlay: false,
  },
  {
    label: "3 Months",
    value: [endOfDay(subMonths(new Date(), 3)), startOfDay(new Date())],
    closeOverlay: false,
  },
  {
    label: "6 Months",
    value: [endOfDay(subMonths(new Date(), 6)), startOfDay(new Date())],
    closeOverlay: false,
  },
  {
    label: "Year",
    value: [endOfDay(subYears(new Date(), 1)), startOfDay(new Date())],
    closeOverlay: false,
  },
];
